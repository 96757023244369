import { gql, useLazyQuery } from "@apollo/client";
import { error_options } from "Common/helpers";
import { SNACK_DURATION, getDate } from "Common/helpers";
import Logo from "../../assets/images/alawadhi_logo.jpeg";
import { ERROR_MESSAGE } from "Common/helpers";
import { concatAllErrors } from "Common/helpers";
import React, { useEffect, useMemo } from "react";
import { Col, Container, Row, Button, Table } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import Base from "Views/base";
import "react-quill/dist/quill.snow.css";

const PatientDataDetail = (props) => {
  const phq9Questions = [
    "Little interest or pleasure in doing things",
    "Feeling down, depressed, or hopeless",
    "Trouble falling or staying asleep, or sleeping too much",
    "Feeling tired or having little energy",
    "Poor appetite or overeating",
    "Feeling bad about yourself – or that you are a failure or have let yourself or your family down",
    "Trouble concentrating on things, such as reading the newspaper or watching television",
    "Moving or speaking so slowly that other people could have noticed? Or the opposite – being so fidgety or restless that you have been moving around a lot more than usual.",
    "Thoughts that you would be better off dead or of hurting yourself in some way.",
  ];

  const gad7Questions = [
    "Feeling nervous, anxious or on edge",
    "Not being able to stop or control worrying",
    "Worrying too much about different things",
    "Trouble relaxing",
    "Being so restless that it is hard to sit still",
    "Becoming easily annoyed or irritable",
    "Feeling afraid as if something awful might happen",
  ];

  const [openSnackbarError] = useSnackbar(error_options);

  const id = props.match.params.id;

  const REQUEST_DATA = gql`
    query patientDataForm($id: ID!) {
      patientDataForm(id: $id) {
        id
        pk
        created
        healthData
        patient {
          id
          firstName
          lastName
          identifier
          phone
          email
          civilId
        }

        appointment {
          id
          identifier
          start
        }
      }
    }
  `;

  // get the details of this prescription
  const [getData, { data }] = useLazyQuery(REQUEST_DATA, {
    onCompleted({ patientDataForm }) {},
    fetchPolicy: "network-only",
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (id) {
      getData({ variables: { id: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderPatientData = useMemo(() => {
    const patientDataRequest = data?.patientDataForm;
    const { patient, appointment, created } = patientDataRequest || {};

    if (!patientDataRequest) {
      return (
        <Row>
          <Col xs={12}>Not found</Col>
        </Row>
      );
    }

    return (
      <>
        <div className="no-print">
          <Button
            className="float-right btn btn-primary"
            onClick={() => window.print()}
          >
            Print
          </Button>

          <p className="mb-0">
            File number: <strong>{patient?.identifier}</strong>
          </p>
          <p className="mb-0">
            Name:{" "}
            <strong>{`${patient?.firstName} ${patient?.lastName}`}</strong>
          </p>
          <p className="mb-0">
            Civil ID: <strong>{patient?.civilId || "-"}</strong>
          </p>
          <p className="mb-0">
            Phone: <strong>{patient?.phone}</strong>
          </p>
          <p className="mb-0">
            Appointment:{" "}
            <a href={`/appointment/detail/${appointment?.id}`} target="_blank">
              <strong>{appointment?.identifier}</strong>
            </a>
          </p>
          <p className="mb-0">
            Created: <strong>{getDate(new Date(created))}</strong>
          </p>
        </div>
        <div className="float-right print">
          <p className="mb-0">
            Name:{" "}
            <strong>{`${patient?.firstName} ${patient?.lastName}`}</strong>
          </p>
          <p className="mb-0">
            Appointment:{" "}
            <strong>{getDate(new Date(appointment?.start))}</strong>
          </p>
        </div>
      </>
    );
  }, [data?.patientDataForm]);

  const assesmentTable = () => {
    // Parse the API response (JSON string)

    const patientDataRequest = data?.patientDataForm;
    const { healthData } = patientDataRequest || {};

    const parsedData = healthData ? JSON.parse(healthData) : {};
    // Calculate total scores
    const calculateTotalScore = (prefix, count) => {
      let total = 0;
      for (let i = 1; i <= count; i++) {
        total += parsedData[`${prefix}_question${i}`] || 0;
      }
      return total;
    };

    const phq9TotalScore = calculateTotalScore("phq9", 9);
    const gad7TotalScore = calculateTotalScore("gad7", 7);

    const renderTable = (questions, prefix, title) => (
      <Table bordered responsive>
        <thead>
          <tr
            style={{
              backgroundColor: "#e9ecef",
              printColorAdjust: "exact",
              WebkitPrintColorAdjust: "exact",
            }}
          >
            <th style={{ width: "50%" }}>{title}</th>
            <th>
              Not at all
              <br />0
            </th>
            <th>
              Several Days
              <br />1
            </th>
            <th>
              More than half the days
              <br />2
            </th>
            <th>
              Nearly every day
              <br />3
            </th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => {
            const answerKey = `${prefix}_question${index + 1}`;
            const answerValue = parsedData[answerKey];

            return (
              <tr key={answerKey}>
                <td style={{ textAlign: "left" }}>{question}</td>
                {[0, 1, 2, 3].map((value) => (
                  <td
                    key={value}
                    style={{
                      fontWeight: answerValue === value ? "bold" : "normal",
                    }}
                  >
                    {answerValue === value ? "●" : ""}
                  </td>
                ))}
              </tr>
            );
          })}
          <tr>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              Total Score
            </td>
            <td colSpan="4" style={{ fontWeight: "bold", textAlign: "center" }}>
              {prefix === "phq9" ? phq9TotalScore : gad7TotalScore}
            </td>
          </tr>
        </tbody>
      </Table>
    );

    return (
      <div className="mt-2">
        <h4>Patient Health Questionnaire (PHQ-9)</h4>
        {renderTable(
          phq9Questions,
          "phq9",
          "Over the last 2 weeks, how often have you been bothered by any of the following problems? (Use a circle to indicate your answer)"
        )}

        <h4>Generalized Anxiety Disorder (GAD-7)</h4>

        {renderTable(
          gad7Questions,
          "gad7",
          "Over the last 2 weeks , how often have you been bothered by the following problems? (Use a circle to indicate your answer)"
        )}
      </div>
    );
  };

  return (
    <>
      <Base title={"Patient health form"} showHeader={false}>
        <Container fluid>
          <Row className="d-block">
            <Col
              xs={12}
              className="mt-2 d-flex align-items-center justify-content-center"
            >
              <img src={Logo} style={{ width: "150px" }} className="print" />
              <h2 style={{ textAlign: "center" }}>
                Dr. Mariam Alawadhi Mental Health Clinic
              </h2>
            </Col>
            <Col xs={12}>{renderPatientData}</Col>
            <Col xs={12}>{assesmentTable()}</Col>
          </Row>
        </Container>
      </Base>
    </>
  );
};

export default PatientDataDetail;
