import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { error_options } from "Common/helpers";
import { SNACK_DURATION } from "Common/helpers";
import { formatDateTime } from "Common/helpers";
import { ERROR_MESSAGE } from "Common/helpers";
import { concatAllErrors } from "Common/helpers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Container,
  Row,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import Base from "Views/base";

import VoiceRecorder from "./VoiceRecorder";
import { success_options } from "Common/helpers";

const InquiryDetail = (props) => {
  const [openSnackbarError] = useSnackbar(error_options);
  const patientId = props.match.params.patientId;
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [actionChange, setActionChange] = useState("");

  const REQUEST_PATIENT = gql`
    query getPatient($id: ID!) {
      patient(id: $id) {
        firstName
        lastName
        inquiries {
          edges {
            node {
              created
              read
              sentToPatientDate
              sentToPatient
              patientNotes
              status
              statusDisplay
              doctor {
                firstName
                lastName
              }
              pk
              doctorNotes
              doctorVoiceNotes
            }
          }
        }
      }
    }
  `;

  // get the details of this prescription
  const [getPatient, { data: patientData }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "no-cache",
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (patientId) {
      getPatient({ variables: { id: patientId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const CHANGE_INQUIRY_STATUS = gql`
    mutation updateInquiryStatus(
      $id: Int!
      $status: String
      $noMessage: Boolean
    ) {
      updateInquiryStatus(id: $id, status: $status, noMessage: $noMessage) {
        obj {
          pk
          status
        }
      }
    }
  `;

  const [changeStatus, { loading: changeStatusLoader }] = useMutation(
    CHANGE_INQUIRY_STATUS,
    {
      onCompleted({ updateInquiryStatus }) {
        if (updateInquiryStatus) {
          setActionChange(Math.random());
          openSnackbarSuccess("Successfully changed status.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const handleChangeStatus = useCallback(
    (item, status, noMessage = false) => {
      let message = `Are you sure you want to mark this ${status}?`;

      if (status === "cancelled") {
        message = `Are you sure you want to mark this CANCEL? By doing so the patient will receive message to notify them.`;

        if (noMessage) message = `Are you sure you want to mark this CANCEL?`;
      }

      if (window.confirm(message)) {
        const payload = {
          id: item?.pk,
          status: status,
        };

        if (noMessage) {
          payload.noMessage = true;
        }

        changeStatus({ variables: payload });
        getPatient({ variables: { id: patientId } });
      }
    },
    [changeStatus, getPatient, patientId]
  );

  const renderInquiries = useCallback(
    (inquiries = []) => {
      return (
        <Accordion defaultActiveKey="0">
          {inquiries.map((item, index) => {
            const node = item?.node;
            const read = node?.read;
            const rawStatus = node?.status;
            const status = node?.statusDisplay;

            const created = node?.created
              ? formatDateTime(new Date(node.created))
              : "-";

            return (
              <Card key={index}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={`${index}`}
                  className="d-flex justify-content-between"
                  disabled={true}
                >
                  <span>
                    Received {created}{" "}
                    {!read && (
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    )}
                  </span>

                  <span>
                    <Badge
                      variant={rawStatus === "CANCELLED" ? "warning" : "info"}
                    >
                      {status}
                    </Badge>
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    {rawStatus === "PENDING" ? (
                      <Dropdown className="float-right">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {node?.status === "PENDING" && (
                            <>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus(node, "cancelled")
                                }
                              >
                                Cancel request
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus(node, "cancelled", true)
                                }
                              >
                                Cancel request without message
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus(node, "completed")
                                }
                              >
                                Manually mark completed
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}

                    {rawStatus === "CANCELLED" ? (
                      <div className="alert alert-danger text-center">
                        <strong>Warning:</strong> This inquiry is cancelled
                      </div>
                    ) : (
                      <>
                        <h5>Patient inquiry</h5>
                        <pre
                          style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          {node?.patientNotes}
                        </pre>
                        <hr />
                        <h5>Practitioner response</h5>

                        <VoiceRecorder
                          audioUrl={node?.doctorVoiceNotes}
                          defaultNotes={node?.doctorNotes}
                          inquiryId={node?.pk}
                          reloadData={() =>
                            getPatient({ variables: { id: patientId } })
                          }
                          status={rawStatus}
                        />
                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      );
    },
    [getPatient, handleChangeStatus, patientId]
  );

  const renderPatientData = useMemo(() => {
    const patient = patientData?.patient;

    if (!patient) {
      return (
        <Row>
          <Col xs={12}>Patient not found</Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col xs={12}>
          <h3>
            {patient?.firstName} {patient?.lastName} Inquiries
          </h3>
        </Col>
        <Col xs={12}>{renderInquiries(patient?.inquiries?.edges)}</Col>
      </Row>
    );
  }, [patientData?.patient, renderInquiries]);

  return (
    <Base title={"Patient inquiries"} showHeader={true}>
      <Container fluid>{renderPatientData}</Container>
    </Base>
  );
};

export default InquiryDetail;
