import React, { useState, useEffect, useRef, useCallback } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";

import { debounce } from "lodash";

import Base from "Views/base.js";
import {
  Pagination,
  Row,
  Col,
  Container,
  Form,
  Table,
  Dropdown,
} from "react-bootstrap";
import Preloader from "Common/Preloder/Preloader.jsx";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  concatAllErrors,
  getDate,
  success_options,
} from "Common/helpers";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import useSearchParamsV5 from "Common/hooks/useSearchParams";
import { removeEmpty } from "Common/helpers";

function PatientData(props) {
  const inputRef = useRef(null);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const [searchParams, setSearchParams] = useSearchParamsV5();
  const [count, setCount] = useState(1);
  const [actionChange, setActionChange] = useState("");
  const patientIdentifier = props.match.params.identifier;

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data: userData } = useQuery(USER_DETAILS);
  var userDetails = userData.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0); // Using a timeout to ensure it focuses after render

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    // this is for page attribute.

    if (searchParams?.page) {
      const page = parseInt(searchParams?.page);

      setActivePage(parseInt(page));

      if (page > 1) {
        setCount(PER_PAGE_COUNT * (page - 1) + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ("search" in searchParams) {
      setSearchTerm(searchParams.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PRESCRIPTION_REQUESTS = gql`
    query (
      $search: String
      $patient_Identifier: String
      $offset: Int
      $first: Int
    ) {
      patientDataForms(
        search: $search
        patient_Identifier: $patient_Identifier
        offset: $offset
        first: $first
      ) {
        totalCount

        edges {
          node {
            pk
            id
            created
            patient {
              id
              firstName
              lastName
              identifier
              phone
              email
            }
            appointment {
              id
              identifier
              start
            }
          }
        }
      }
    }
  `;

  const [getPrescriptions, { data, loading }] = useLazyQuery(
    PRESCRIPTION_REQUESTS,
    {
      fetchPolicy: "network-only",
      pollInterval: 5 * 60 * 1000, // 5mins
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    let payload = {
      offset: 0,
      first: PER_PAGE_COUNT,
    };

    if (userDetails?.doctor?.identifier) {
      payload = {
        ...payload,
        doctor_Identifier: userDetails?.doctor?.identifier,
      };
    }

    payload = {
      ...payload,
      ...searchParams,
    };

    if (searchParams?.page) {
      payload.offset = PER_PAGE_COUNT * parseInt(searchParams.page - 1);
    }

    if (patientIdentifier) {
      payload.patient_Identifier = patientIdentifier;
    }

    payload = removeEmpty(payload);
    getPrescriptions({ variables: payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams?.search,
    searchParams?.start,
    searchParams?.end,
    getPrescriptions,
    actionChange,
    patientIdentifier,
  ]);

  const total_pages =
    data && data.patientDataForms && data.patientDataForms.totalCount
      ? Math.ceil(data.patientDataForms.totalCount / PER_PAGE_COUNT)
      : 1;

  const patients =
    data && data.patientDataForms ? data.patientDataForms.edges : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(
    debounce((value) => {
      setSearchParams({ ...searchParams, search: value, page: 1 });
    }, 500),
    [] // Dependency array can be left empty, as it doesn't depend on any external state
  );

  const handleChange = (event) => {
    const value = event.target.value;
    handleDebouncedSearch(value);
    setSearchTerm(event.target.value);
  };

  // const createPrescription = (node) => {
  //   history.push({
  //     pathname: `/prescription/print/${node.id}`,
  //   });
  // };

  const renderPages = () => {
    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  const handlePageChange = (number) => {
    setSearchParams({ ...searchParams, page: number });
    setActivePage(number);

    if (number === 1) {
      setCount(1);
    } else {
      setCount(PER_PAGE_COUNT * (number - 1) + 1);
    }
  };

  return (
    <Base title={"Patient health forms"} showHeader={true}>
      <Container fluid>
        <Row className="mb-2">
          <Col>
            <Form.Group as={Row}>
              <Form.Label column md={6}>
                Search
                <Form.Control
                  ref={inputRef}
                  autoComplete="off"
                  type="text"
                  placeholder="Appt.ID"
                  value={searchTerm}
                  onChange={handleChange}
                  column
                  md={8}
                />
              </Form.Label>

              <Form.Label column md={6}>
                Total number
                <h4>
                  <strong>{data?.patientDataForms?.totalCount || 0}</strong>
                </h4>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="patient_table_row request-table">
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="align-middle">No.</th>
                <th className="align-middle">File number</th>
                <th className="align-middle">Patient name</th>
                <th className="align-middle">Phone number</th>
                <th className="align-middle">Appt. date</th>
                <th className="align-middle">Date created</th>
                <th className="align-middle">Actions</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              </tbody>
            ) : patients.length > 0 ? (
              <tbody>
                {patients.map((item, index) => {
                  const itemNode = item?.node;
                  const { patient, appointment } = itemNode || {};

                  console.log(patient);

                  const patientName = `${patient.firstName} ${patient.lastName}`;
                  // const doctorName = `${doctor.firstName} ${doctor.lastName}`;

                  const created = itemNode?.created
                    ? getDate(new Date(itemNode?.created))
                    : "-";

                  return (
                    <tr key={index}>
                      <td className="align-middle">{index + count}</td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patient.identifier}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patientName}
                        </Link>
                      </td>
                      <td className="align-middle">{patient.phone || "-"}</td>
                      <td className="align-middle">
                        {getDate(new Date(appointment?.start))}
                      </td>

                      <td className="align-middle">{created}</td>

                      <td className="align-middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href={`/patient/health/data/details/${itemNode.id}`}
                              target={"_blank"}
                            >
                              Open details
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="align-middle text-center" colSpan={8}>
                    Not Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Row>

        {data?.patientDataForms?.totalCount > PER_PAGE_COUNT ? (
          <div className="paginationContainer pt-1">
            <Pagination>{renderPages()}</Pagination>
          </div>
        ) : null}
      </Container>
    </Base>
  );
}
export default withRouter(PatientData);
