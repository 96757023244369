import React, { useState, useEffect, useRef, useCallback } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { debounce } from "lodash";

import Base from "Views/base.js";
import {
  Pagination,
  Row,
  Col,
  Container,
  Form,
  Table,
  Dropdown,
} from "react-bootstrap";
import Preloader from "Common/Preloder/Preloader.jsx";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  concatAllErrors,
  getDate,
  success_options,
} from "Common/helpers";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import useSearchParamsV5 from "Common/hooks/useSearchParams";
import { removeEmpty } from "Common/helpers";
import { getPermissionForAction } from "Common/helpers";

function PrescriptionRequests(props) {
  const inputRef = useRef(null);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const [searchParams, setSearchParams] = useSearchParamsV5();
  const [count, setCount] = useState(1);
  const [actionChange, setActionChange] = useState("");
  const patientIdentifier = props.match.params.identifier;

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const REQUEST_USER_PERMISSION = gql`
    query receiveDate {
      userPermissions @client
    }
  `;

  const { data: userData } = useQuery(USER_DETAILS);
  var userDetails = userData.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const { data: user_permissions = { user_permissions: [] } } = useQuery(
    REQUEST_USER_PERMISSION
  );

  const hasApprovePermission = getPermissionForAction(
    "pres_request",
    "approved",
    user_permissions.userPermissions
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0); // Using a timeout to ensure it focuses after render

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    // this is for page attribute.

    if (searchParams?.page) {
      const page = parseInt(searchParams?.page);

      setActivePage(parseInt(page));

      if (page > 1) {
        setCount(PER_PAGE_COUNT * (page - 1) + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ("search" in searchParams) {
      setSearchTerm(searchParams.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PRESCRIPTION_REQUESTS = gql`
    query (
      $search: String
      $status: String
      $doctor_Identifier: String
      $patient_Identifier: String
      $offset: Int
      $start: Date
      $end: Date
      $first: Int
    ) {
      patientPrescriptionRequests(
        search: $search
        status: $status
        doctor_Identifier: $doctor_Identifier
        patient_Identifier: $patient_Identifier
        offset: $offset
        start: $start
        end: $end
        first: $first
      ) {
        totalCount

        edges {
          node {
            pk
            id
            created
            status
            shippingDisplay
            statusDisplay
            patientNotes
            order
            read
            controlledPrescription
            pharmacy {
              titleEn
            }
            prescription {
              id
            }
            patient {
              id
              firstName
              lastName
              identifier
              phone
              email
            }
            doctor {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `;

  const CHANGE_REQUEST_STATUS = gql`
    mutation changePresRequestStatus(
      $id: Int!
      $status: String
      $notes: String
      $read: Boolean
    ) {
      changePresRequestStatus(
        id: $id
        status: $status
        notes: $notes
        read: $read
      ) {
        obj {
          pk
          status
        }
      }
    }
  `;

  const CHANGE_CONTROL_PRES = gql`
    mutation changePresControlled($id: Int!) {
      changePresControlled(id: $id) {
        obj {
          pk
          controlledPrescription
        }
      }
    }
  `;

  const [changeStatus, { loading: changeStatusLoader }] = useMutation(
    CHANGE_REQUEST_STATUS,
    {
      onCompleted({ changePresRequestStatus }) {
        if (changePresRequestStatus) {
          setActionChange(changePresRequestStatus?.obj?.status);
          openSnackbarSuccess("Successfully changed status.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [changeControlled, { loading: controlledLoader }] = useMutation(
    CHANGE_CONTROL_PRES,
    {
      onCompleted({ changePresControlled }) {
        if (changePresControlled) {
          setActionChange(Math.random());
          openSnackbarSuccess("Successfully changed.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getPrescriptions, { data, loading }] = useLazyQuery(
    PRESCRIPTION_REQUESTS,
    {
      fetchPolicy: "network-only",
      pollInterval: 5 * 60 * 1000, // 5mins
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    let payload = {
      offset: 0,
      first: PER_PAGE_COUNT,
    };

    if (userDetails?.doctor?.identifier) {
      payload = {
        ...payload,
        doctor_Identifier: userDetails?.doctor?.identifier,
      };
    }

    payload = {
      ...payload,
      ...searchParams,
    };

    if (searchParams?.page) {
      payload.offset = PER_PAGE_COUNT * parseInt(searchParams.page - 1);
    }

    if (patientIdentifier) {
      payload.patient_Identifier = patientIdentifier;
    }

    payload = removeEmpty(payload);
    getPrescriptions({ variables: payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams?.search,
    searchParams?.start,
    searchParams?.end,
    getPrescriptions,
    actionChange,
    patientIdentifier,
  ]);

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setSearchParams({
        ...searchParams,
        start: date ? moment(date).format("YYYY-MM-DD") : "",
        page: 1,
      });
    } else {
      setSearchParams({
        ...searchParams,
        end: date ? moment(date).format("YYYY-MM-DD") : "",
        page: 1,
      });
    }
  };

  const REQUEST_DOCTOR = gql`
    query {
      doctors(publicPractitioner: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctors error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleDoctor = (event) => {
    setSearchParams({
      ...searchParams,
      doctor_Identifier: event.target.value,
      page: 1,
    });
  };

  const handleStatusChange = (event) => {
    setSearchParams({
      ...searchParams,
      status: event.target.value,
      page: 1,
    });
  };

  const total_pages =
    data &&
    data.patientPrescriptionRequests &&
    data.patientPrescriptionRequests.totalCount
      ? Math.ceil(data.patientPrescriptionRequests.totalCount / PER_PAGE_COUNT)
      : 1;

  const patients =
    data && data.patientPrescriptionRequests
      ? data.patientPrescriptionRequests.edges
      : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(
    debounce((value) => {
      setSearchParams({ ...searchParams, search: value, page: 1 });
    }, 500),
    [] // Dependency array can be left empty, as it doesn't depend on any external state
  );

  const handleChange = (event) => {
    const value = event.target.value;
    handleDebouncedSearch(value);
    setSearchTerm(event.target.value);
  };

  const createPrescription = (node) => {
    history.push({
      pathname: `/prescription/print/${node.id}`,
    });
  };

  const renderPages = () => {
    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  const handlePageChange = (number) => {
    setSearchParams({ ...searchParams, page: number });
    setActivePage(number);

    if (number === 1) {
      setCount(1);
    } else {
      setCount(PER_PAGE_COUNT * (number - 1) + 1);
    }
  };

  const handleChangeStatus = useCallback(
    (item, status) => {
      let message = `Are you sure you want to mark this ${status}?`;

      if (status === "cancelled") {
        message = `Are you sure you want to mark this CANCEL? By doing so the patient will receive message to notify them.`;
      }

      if (window.confirm(message)) {
        const payload = {
          id: item?.pk,
          status: status,
        };

        changeStatus({ variables: payload });
      }
    },
    [changeStatus]
  );

  const handleReadStatus = useCallback(
    (item) => {
      let message = "";
      if (item?.read) {
        message = `Are you sure you want to mark this unread?`;
      } else {
        message = `Are you sure you want to mark this read?`;
      }

      if (window.confirm(message)) {
        const payload = {
          id: item?.pk,
          read: !item?.read,
        };

        changeStatus({ variables: payload });
      }
    },
    [changeStatus]
  );

  return (
    <Base title={"Prescription requests"} showHeader={true}>
      <Container fluid>
        <Row className="mb-2">
          <Col>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                Search
                <Form.Control
                  ref={inputRef}
                  autoComplete="off"
                  type="text"
                  placeholder="File #/CivilID/Phone"
                  value={searchTerm}
                  onChange={handleChange}
                  column
                  md={8}
                />
              </Form.Label>
              <Form.Label
                column
                md={3}
                className={
                  userDetails?.isSuperuser
                    ? ""
                    : userDetails?.doctor?.identifier
                    ? "d-none"
                    : ""
                }
              >
                Practitioner
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="doctor"
                  value={
                    searchParams?.doctor_Identifier ||
                    userDetails?.doctor?.identifier ||
                    ""
                  }
                  onChange={handleDoctor}
                  column
                  md={8}
                >
                  <option value="">Select Practitioner</option>
                  {doctors &&
                    doctors?.doctors?.edges?.map((doctor) => {
                      return (
                        <option
                          value={doctor.node.identifier}
                          key={doctor.node.id}
                        >
                          {doctor.node.firstName} {doctor.node.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Status
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="status"
                  value={searchParams?.status || ""}
                  onChange={handleStatusChange}
                  column
                  md={8}
                >
                  <option value="">Select Status</option>
                  <option value="pending">Pending</option>
                  {/* <option value="inprogress">In progress</option> */}
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Start
                <DatePicker
                  selected={
                    searchParams?.start ? new Date(searchParams.start) : ""
                  }
                  onChange={(date) => handleDateChange("start", date)}
                  name="start"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>
              <Form.Label column md={3}>
                End
                <DatePicker
                  selected={searchParams?.end ? new Date(searchParams.end) : ""}
                  onChange={(date) => handleDateChange("end", date)}
                  name="end"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>

              <Form.Label column md={3}>
                Total number
                <h4>
                  <strong>
                    {data?.patientPrescriptionRequests?.totalCount || 0}
                  </strong>
                </h4>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="patient_table_row request-table">
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="align-middle">No.</th>
                <th className="align-middle">File number</th>
                <th className="align-middle">Patient Name</th>
                <th className="align-middle">Phone Number</th>
                <th className="align-middle">Delivery mode</th>
                <th className="align-middle">Status</th>
                <th className="align-middle">Date requested</th>
                <th className="align-middle">Notes</th>
                <th className="align-middle">Actions</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              </tbody>
            ) : patients.length > 0 ? (
              <tbody>
                {patients.map((item, index) => {
                  const itemNode = item?.node;
                  const patient = itemNode?.patient;
                  // const doctor = itemNode?.doctor;
                  const status = itemNode.status;

                  const patientName = `${patient.firstName} ${patient.lastName}`;
                  // const doctorName = `${doctor.firstName} ${doctor.lastName}`;

                  const created = itemNode?.created
                    ? getDate(new Date(itemNode?.created))
                    : "-";

                  return (
                    <tr
                      key={index}
                      className={status === "PENDING" ? "bg-grey" : ""}
                    >
                      <td className="align-middle">
                        {index + count}{" "}
                        {!itemNode?.read && (
                          <i
                            className="fa fa-envelope mr-1"
                            aria-hidden="true"
                          ></i>
                        )}
                        {itemNode?.controlledPrescription && (
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        )}
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patient.identifier}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patientName}
                        </Link>
                      </td>
                      <td className="align-middle">{patient.phone || "-"}</td>
                      <td className="align-middle">
                        {itemNode?.shippingDisplay}
                        <br />
                        {itemNode?.pharmacy?.titleEn
                          ? `(${itemNode?.pharmacy?.titleEn})`
                          : null}
                      </td>
                      <td className="align-middle">
                        {itemNode?.statusDisplay}
                      </td>

                      <td className="align-middle">{created}</td>
                      <td className="align-middle">
                        <div className="ellipsis">
                          {itemNode?.patientNotes || "-"}
                        </div>
                      </td>

                      <td className="align-middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {!itemNode?.prescription &&
                              itemNode?.status === "PENDING" && (
                                <>
                                  {(itemNode?.read || hasApprovePermission) && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          createPrescription(itemNode)
                                        }
                                      >
                                        Create
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href={`/patient/prescription/history/${patient.identifier}/${itemNode.id}`}
                                      >
                                        Create from existing
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleChangeStatus(itemNode, "cancelled")
                                    }
                                  >
                                    Cancel request
                                  </Dropdown.Item>
                                </>
                              )}
                            {hasApprovePermission && (
                              <Dropdown.Item
                                onClick={() => handleReadStatus(itemNode)}
                              >
                                {itemNode?.read ? "Un approved" : "Approved"}
                              </Dropdown.Item>
                            )}

                            {itemNode?.prescription?.id ? (
                              <Dropdown.Item
                                href={`/prescription/details/${itemNode.prescription.id}`}
                                target={"_blank"}
                              >
                                Open prescription
                              </Dropdown.Item>
                            ) : null}

                            <Dropdown.Item
                              onClick={() =>
                                changeControlled({
                                  variables: { id: itemNode?.pk },
                                })
                              }
                            >
                              {itemNode?.controlledPrescription
                                ? "Unmark controlled"
                                : "Mark controlled"}
                            </Dropdown.Item>

                            {/* {itemNode?.prescription &&
                              itemNode?.status !== "COMPLETED" && (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleChangeStatus(itemNode, "completed")
                                  }
                                >
                                  Mark completed
                                </Dropdown.Item>
                              )}

                            {(itemNode?.status === "COMPLETED" ||
                              itemNode?.status === "CANCELLED") && (
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus(itemNode, "pending")
                                }
                              >
                                Mark pending
                              </Dropdown.Item>
                            )} */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="align-middle text-center" colSpan={8}>
                    Not Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Row>

        {data?.patientPrescriptionRequests?.totalCount > PER_PAGE_COUNT ? (
          <div className="paginationContainer pt-1">
            <Pagination>{renderPages()}</Pagination>
          </div>
        ) : null}
      </Container>
    </Base>
  );
}
export default withRouter(PrescriptionRequests);
