import { Card, Table, Button, Form } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  validateDecimal,
} from "Common/helpers.js";
import { useSnackbar } from "react-simple-snackbar";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

function PatientDoctorDiscountComponent(props) {
  /** 
		Patient doctor discount component
		will include List/create/update/delete.
	**/
  const formRef = useRef(null);
  let { patient_record } = props;

  if (patient_record.node) {
    patient_record = patient_record.node;
  }

  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  const [discountErrorMessage, setDiscountErrorMessage] = useState(null);
  const [discountSuccessMessage, setdiscountSuccessMessage] = useState(null);
  const [editing, setEditing] = useState(null);

  const handleReset = () => {
    // handle reset will reset all the fields on the form.
    // added a custom clearing for initial states
    setDiscountErrorMessage(null);
    setdiscountSuccessMessage(null);
    formRef.current.reset();
    setValidated(false);
    setEditing(null);
  };

  const handleClose = () => {
    // closing or canceling when your inside the modal
    setShow(false);
    handleReset();
  };

  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    // submitting form for patient discount
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData(event.target),
        formDataObj = Object.fromEntries(formData.entries());

      // now that we have a valid form and dictionary of it we can post in now to BE to create or updated
      createUpdatePatientDoctorFunction({
        variables: formDataObj,
      });
    }

    setValidated(true);
  };

  const CREATE_UPDATE_PATIENT_DOCTOR_DISCOUNT_MUTATION = gql`
    mutation createUpdatePatientDoctorDiscount(
      $patient: ID!
      $id: ID
      $doctor: ID!
      $discount: Float!
    ) {
      createUpdatePatientDoctorDiscount(
        input: {
          patient: $patient
          id: $id
          doctor: $doctor
          discount: $discount
        }
      ) {
        obj {
          id
          patient {
            id
            patientDoctorDiscount {
              edges {
                node {
                  id
                  doctor {
                    identifier
                    firstName
                    lastName
                    id
                  }
                  discount
                }
              }
            }
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DELETE_PATIENT_DOCTOR_DISCOUNT_MUTATION = gql`
    mutation deletePatientDoctorDiscount($id: ID!) {
      deletePatientDoctorDiscount(id: $id) {
        deleted
      }
    }
  `;

  const [
    createUpdatePatientDoctorFunction,
    {
      data: discountData,
      loading: discountLoading,
      error: discountError,
      reset: discountReset,
    },
  ] = useMutation(CREATE_UPDATE_PATIENT_DOCTOR_DISCOUNT_MUTATION, {
    onCompleted(data) {
      if (data?.createUpdatePatientDoctorDiscount?.errors?.length > 0) {
        // NOTE: errors are list of objects.  and we care about 'messages'
        // which is a list of messages
        const discount_errors =
          data.createUpdatePatientDoctorDiscount.errors[0];

        if ("messages" in discount_errors) {
          let message = "";
          let count = 1;
          for (const x of discount_errors.messages) {
            if (count > 1) {
              message = message + ", " + x;
            } else {
              message = x;
            }
          }

          if (message) {
            setDiscountErrorMessage(message);
          }
        }
      } else {
        patient_record = data?.createUpdatePatientDoctorDiscount?.obj?.patient;
        handleClose();
      }
    },
    onError(error) {
      openSnackbar(error.message, [SNACK_DURATION]);
    },
  });

  const REQUEST_PATIENT = gql`
		query {
			patient(id:"${patient_record?.id}") {
					id
					patientDoctorDiscount{
						edges{
							node{
								id
								doctor{
									identifier
									firstName
									lastName
									id
								}
								discount
							}
						}
					}
			}
		}				
	`;

  const REQUEST_DOCTORS = gql`
    query {
      doctors(user_IsActive: true) {
        edges {
          node {
            firstName
            lastName
            id
            identifier
          }
        }
      }
    }
  `;

  const [
    requestDoctorsFunction,
    {
      data: doctorsData,
      loading: doctorsLoading,
      error: doctorsError,
      reset: doctorsReset,
    },
  ] = useLazyQuery(REQUEST_DOCTORS, {
    onError(error) {
      openSnackbar(error.message, [SNACK_DURATION]);
    },
  });

  const [requestPatient] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      patient_record = data?.patient?.node;
    },
    onError(error) {
      openSnackbar(error.message, [SNACK_DURATION]);
    },
  });

  const [deletePatientDoctorDiscount] = useMutation(
    DELETE_PATIENT_DOCTOR_DISCOUNT_MUTATION,
    {
      onCompleted(data) {
        if (data?.deletePatientDoctorDiscount?.deleted === true) {
          openSnackbarSuccess("Discount deleted.", [SNACK_DURATION]);
          requestPatient();
        }
      },
      onError(error) {
        openSnackbar(error.message, [SNACK_DURATION]);
        requestPatient();
      },
    }
  );

  const editDiscount = (item) => {
    setEditing(item);
    setShow(true);
  };

  const deleteDiscount = (item) => {
    deletePatientDoctorDiscount({
      variables: { id: item.node.id },
    });
  };

  const renderPatientDiscountItems = () => {
    let discounts = patient_record?.patientDoctorDiscount?.edges;

    if (discounts?.length > 0) {
      return discounts.map((item, index) => {
        return (
          <tr key={index}>
            <td className="align-middle">
              {item.node.doctor.firstName} {item.node.doctor.lastName}
            </td>
            <td className="align-middle">{item.node.discount}</td>
            <td className="align-middle d-flex flex-row">
              <Button
                onClick={() => editDiscount(item)}
                size="sm"
                variant="primary"
                className="mr-2"
              >
                Edit
              </Button>
              <Button
                onClick={() => deleteDiscount(item)}
                size="sm"
                variant="danger"
                className="removeRef "
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });
    }
    return null;
  };

  const renderDoctorOptions = () => {
    let doctors = doctorsData?.doctors?.edges;

    if (doctors?.length > 0) {
      return doctors.map((item, index) => {
        return (
          <option key={index} value={item.node.id}>
            {item.node.firstName} {item.node.lastName}
          </option>
        );
      });
    } else {
      return <option value="">No available doctors.</option>;
    }
  };

  useEffect(() => {
    // first we need the list of active doctors
    requestDoctorsFunction();
  }, []); // passing an empty list means it will only load once when this component is mounted

  return (
    <>
      <Card className="col-md-10 mt-5 controlled-prescription-table no-print">
        <Card.Body>
          <Card.Title className="d-flex flex-row justify-content-between align-items-center">
            Patient Doctor Discounts
            <div>
              <Button
                variant="primary"
                size="md"
                className="px-0"
                onClick={handleShow}
              >
                <i className="fa fa-plus pr-2" aria-hidden="true"></i> Create
                new
              </Button>
            </div>
          </Card.Title>
          <Table responsive>
            <thead>
              <tr>
                <th>Doctor</th>
                <th>Discount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderPatientDiscountItems()}</tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editing ? "Updating discount" : "Create new discount"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {discountErrorMessage ? (
            <Alert key="danger" variant="danger">
              {discountErrorMessage}
            </Alert>
          ) : null}

          {discountSuccessMessage ? (
            <Alert key="success" variant="success">
              {discountSuccessMessage}
            </Alert>
          ) : null}

          <Form
            ref={formRef}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3" controlId="formBasicDoctor">
              <Form.Label>Doctor</Form.Label>
              <Form.Control
                as="select"
                name="doctor"
                required
                defaultValue={editing?.node?.doctor?.id}
              >
                <option value="">Select doctor</option>
                {renderDoctorOptions()}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDiscount">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                required
                name="discount"
                defaultValue={editing ? editing.node.discount : null}
                step="any"
                onInput={(e) => validateDecimal(e)}
                type="number"
              />
            </Form.Group>
            <Form.Control
              required
              name="patient"
              defaultValue={patient_record?.id}
              type="hidden"
            />
            {editing ? (
              <Form.Control
                required
                name="id"
                defaultValue={editing.node.id}
                type="hidden"
              />
            ) : null}
            <Button variant="secondary" className="m-1" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="m-1"
              type="submit"
              disabled={discountLoading ? true : false}
            >
              {discountLoading ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PatientDoctorDiscountComponent;
