import React from "react";
import LoginPage from "./component/LoginPage/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import AppointmentCreate from "./Views/appointmentCreate";
import AppointmentCreatePast from "./Views/appointmentCreatePast";
import Dashboard from "./Views/dashboard";
import Patients from "./Views/patients";
import MyAppointments from "./Views/my_appointments";
import WaitingList from "./Views/waiting_list";
import Payments from "./Views/payments";
import PendingPayments from "./Views/pendingPayments";
import PrescriptionRequests from "component/Prescriptions/Requests";
import Inquiries from "component/Inquiries/Inquiries";
import InquiryDetail from "component/Inquiries/InquiryDetail";
import MedicalRequest from "component/Medical/MedicalRequest";
import PatientData from "component/PatientData/PatientData";
import PatientDataDetail from "component/PatientData/PatientDataDetail";

import PaymentsPrintDetail from "./Views/PaymentsPrintDetail";
import PendingPaymentsPrintDetail from "./Views/pendingPaymentsPrintDetail";

import PrescriptionPrint from "./Views/PrescriptionPrint";
// import Settings from "./Views/settings";
import CreateNewPatient from "./Views/CreateNewPatient/CreateNewPatientHoc";
import ManualPayment from "./Views/manual_payment";
import PatientRecord from "./Views/patient_record";
import PatientRecordFiles from "./Views/patientRecordFiles";
import AppointmentDetail from "./Views/appointment_detail";
import SearchForPatient from "./Views/SearchForPatient/searchForPatientPage";
import SnackbarProvider from "react-simple-snackbar";
import VerifyToken from "./verifyToken";
import PatientPrescriptionHistory from "./Views/patientPrescriptionHistory";
import PrescriptionDetail from "Views/PrescriptionDetail";
import MedicalDetail from "component/Medical/MedicalDetail";

const App = () => {
  let location = useLocation();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <>
            <VerifyToken />
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
  const PublicOnlyRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/dashboard",
            }}
          />
        ) : (
          <>
            <VerifyToken />
            <Component {...props} />
          </>
        )
      }
    />
  );
  return (
    <SnackbarProvider>
      <Switch>
        <PrivateRoute
          exact
          path="/patient/record/:identifier"
          component={PatientRecord}
        />
        <PrivateRoute
          exact
          path="/create/appointment"
          component={AppointmentCreate}
        />
        <PrivateRoute
          exact
          path="/create/past/appointment/:patientId"
          component={AppointmentCreatePast}
        />
        <PrivateRoute
          exact
          path="/appointment/detail/:appointmentId"
          component={AppointmentDetail}
        />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/patients" component={Patients} />
        <PrivateRoute
          exact
          path="/prescription/print"
          component={PrescriptionPrint}
        />
        <PrivateRoute
          exact
          path="/prescription/print/:requestid"
          component={PrescriptionPrint}
        />

        <PrivateRoute
          exact
          path="/patient/prescription/history/:identifier"
          component={PatientPrescriptionHistory}
        />
        <PrivateRoute
          exact
          path="/patient/prescription/history/:identifier/:requestid"
          component={PatientPrescriptionHistory}
        />
        <PrivateRoute
          exact
          path="/patient/record/files/:id"
          component={PatientRecordFiles}
        />
        <PrivateRoute exact path="/appointments" component={MyAppointments} />
        <PrivateRoute exact path="/manual/payment" component={ManualPayment} />
        <PrivateRoute
          exact
          path="/search/patient"
          component={SearchForPatient}
        />
        <PrivateRoute
          exact
          path="/create/patient"
          component={CreateNewPatient}
        />
        <PrivateRoute
          exact
          path="/edit/patient/:identifier"
          component={CreateNewPatient}
        />
        <PrivateRoute exact path="/waiting/list" component={WaitingList} />
        <PrivateRoute exact path="/payments" component={Payments} />
        <PrivateRoute
          exact
          path="/payments/detail/:id"
          component={PaymentsPrintDetail}
        />

        <PrivateRoute
          exact
          path="/pending/payments"
          component={PendingPayments}
        />
        <PrivateRoute
          exact
          path="/pending/payments/detail/:id"
          component={PendingPaymentsPrintDetail}
        />

        {/* Prescription requests routes */}
        <PrivateRoute
          exact
          path="/requests/prescriptions"
          component={PrescriptionRequests}
        />
        <PrivateRoute
          exact
          path="/requests/prescriptions/patient/:identifier"
          component={PrescriptionRequests}
        />
        <PrivateRoute
          exact
          path="/patient/health/data/:identifier"
          component={PatientData}
        />
        <PrivateRoute
          exact
          path="/patient/health/data/details/:id"
          component={PatientDataDetail}
        />
        <PrivateRoute
          exact
          path="/prescription/details/:prescriptionId"
          component={PrescriptionDetail}
        />

        {/* Inquiries */}
        <PrivateRoute exact path="/requests/inquiries" component={Inquiries} />
        <PrivateRoute
          exact
          path="/requests/inquiries/:patientId"
          component={InquiryDetail}
        />

        {/* Medical report routes */}
        <PrivateRoute
          exact
          path="/requests/medical"
          component={MedicalRequest}
        />
        <PrivateRoute
          exact
          path="/requests/medical/:currentStatus"
          component={MedicalRequest}
        />
        <PrivateRoute
          exact
          path="/medical/details/:medicalId"
          component={MedicalDetail}
        />

        {/* <PrivateRoute exact path="/settings" component={Settings} /> */}
        <PublicOnlyRoute exact path="/" component={LoginPage} />
        <Route path="/password-reset/" component={LoginPage} />
        <Route path="/forgot/password/" component={LoginPage} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </SnackbarProvider>
  );
};

export default App;
