import { Button, Card } from "react-bootstrap";
import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  getForamttedTime,
  getDay,
  getDate,
  SNACK_DURATION,
  success_options,
  concatAllErrors,
  ERROR_MESSAGE,
  error_options,
} from "../Common/helpers.js";
import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";

function UpcomingAppointmentCard(props) {
  let { event, detail, location, extraClasses, refetch } = props;
  const { pathname } = location;
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [openSnackbarError] = useSnackbar(error_options);

  const SEND_ZOOM = gql`
    mutation sendZoomLink($id: Int!) {
      sendZoomLink(id: $id) {
        obj {
          zoomLinkSent
        }
      }
    }
  `;

  const [sendZoomLink, { loading: zoomLoading }] = useMutation(SEND_ZOOM, {
    onCompleted({ sendZoomLink }) {
      if (sendZoomLink) {
        if (sendZoomLink?.obj?.zoomLinkSent) {
          refetch?.();

          openSnackbarSuccess("Zoom link sent", [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const sendZoom = useCallback(() => {
    if (window.confirm(`Are you sure you want to send a zoom link?`)) {
      const payload = {
        id: event?.eventId,
      };

      sendZoomLink({ variables: payload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.pk]);

  const getCard = () => {
    let date = new Date(event.start);
    return (
      <Card className={extraClasses ? extraClasses : ""}>
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap">
            <span> {getDay(date.getDay())} </span>
            <span>{getDate(date)}</span>
            <span>{getForamttedTime(date)}</span>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <span>
              {" "}
              {event.eventType && event.eventType.title
                ? event.eventType.title
                : null}{" "}
            </span>
            <span className="text-capitalize"> {event.displayStatus} </span>
          </div>
          {detail ? (
            <div className="d-flex justify-content-between mt-3">
              {event.location ? (
                <div className="text-capitalize">
                  {" "}
                  Location: {event.location.title}{" "}
                </div>
              ) : null}
              <span className="text-capitalize">
                {" "}
                Duration: {event.duration} Minutes{" "}
              </span>
            </div>
          ) : null}
          <div className="d-flex justify-content-between mt-3 ">
            {event.doctor ? (
              <span className="text-capitalize">
                {" "}
                Practitioner: {event.doctor.firstName} {event.doctor.lastName}{" "}
              </span>
            ) : null}
            {/* <span className="text-capitalize"> File No.: {event.patient.identifier} </span> */}
          </div>

          {event?.location?.title?.toLowerCase()?.includes("online") && (
            <>
              <Button
                variant="primary"
                size="md"
                onClick={sendZoom}
                disabled={zoomLoading}
                className="mt-3"
              >
                Send zoom link
              </Button>

              <div className="d-flex align-items-center mt-3">
                <p className="mb-0">Zoom link sent:</p>
                {event.zoomLinkSent ? (
                  <i className="fa fa-check ml-2" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-times ml-2" aria-hidden="true"></i>
                )}
              </div>
              {event?.doctorEventType?.zoomLink && (
                <Button
                  variant="secondary"
                  size="md"
                  href={event.doctorEventType?.zoomLink}
                  target="_blank"
                >
                  Open zoom
                </Button>
              )}
              <div className="d-flex align-items-center mt-3">
                <p className="mb-0">Health form submitted</p>
                {event?.patientDataForm ? (
                  <i className="fa fa-check ml-2" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-times ml-2" aria-hidden="true"></i>
                )}
              </div>
              {event?.patientDataForm && (
                <Button
                  href={`/patient/health/data/details/${event?.patientDataForm?.id}`}
                  target="_blank"
                >
                  Open health form
                </Button>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    );
  };

  if (event) {
    if (pathname.indexOf("/appointment/detail/") > -1) {
      return getCard();
    } else {
      return (
        <Link to={"/appointment/detail/" + event.id} className="dark_link">
          {getCard()}
        </Link>
      );
    }
  }
  return null;
}
export default withRouter(UpcomingAppointmentCard);
